<template>
    <div class="mp-container">
        <iframe style="border: 0; width: 350px; height: 786px;" 
        src="https://bandcamp.com/EmbeddedPlayer/album=1164217767/size=large/bgcol=ffffff/linkcol=e99708/transparent=true/" seamless>
        <a href="https://judithgap.bandcamp.com/album/pine-sap-burner">Pine Sap Burner by Judith Gap</a></iframe>    
    </div>
</template>

<script>
export default {
    name: 'MusicPlayer',
}
</script>

<style>

</style>
