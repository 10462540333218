<template>
    <div class="lp-container">
        <div class="lp-row">
            <header>
                <img src="../assets/headerlogo.png"/>
            </header>
        </div>
        <div class="lp-row">
            <MusicPlayer/>
        </div>
    </div>
</template>

<script>
import MusicPlayer from './MusicPlayer.vue';

export default {
    name: 'LandingPage',
    components: {
        MusicPlayer,
    },
}
</script>

<style>
.lp-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.lp-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
